import { ANTIBIOTIC_RESISTANCE_GENES_TYPE } from "constant";

import React, { memo, useContext, useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import Select from "react-select";
import { generateRefInterval } from "utils";

import TestInputField from "./TestInputField";

import TestDetailsRangeView from "./TestDetailsRangeView/TestDetailsRangeView";

import TestDetailsRangeViewNew from "./TestDetailsRangeView/TestDetailsRangeViewNew";

import { AppContext } from "context/app-context";
import { customIsEmpty } from "util/customLodash";
import TestSelector from "./TestUnitSelector/TestSelector";
import { customCloneDeep } from "util/customLodash";
import { TEST_SETTING_TYPES } from "constant";
import Loader from "components/Loader/Loader";
import { TYPE_OF_TEST } from "constant";
import Icon from "components/Icon";

const TestDetailsModal = ({ data, handleSaveTestDetail, handleClose, loading }) => {
  const appContext = useContext(AppContext);

  const emptyRowObj = {
    AgeStart: "",
    AgeEnd: "",
    NormalLow: "",
    NormalHigh: "",
    HighLow: "",
    HighHigh: "",
    PanicLow: "",
    PanicHigh: "",
    RepeatLow: "",
    RepeatHigh: "",
    LowLow: "",
    LowHigh: "",
  };

  const emptyNode = {
    RangesAdded: false,
    FilterKey: [null],
    collapse: false,
    RangeArr: [emptyRowObj],
  };

  const [newItem, setNewItem] = useState({
    isNew: true,
    name: "",
    units: null,
    refInterval: "",
    isActive: "",
  });

  // const [ranges, setRanges] = useState([emptyNode]);

  const [ranges, setRanges] = useState(() => {
    const defaultRangeObj = {
      RangesAdded: false,
      FilterKey: [null],
      collapse: false,
      RangeArr: [emptyRowObj],
    };

    const keysToMoveToRangeArr = [
      "LowLow",
      "HighLow",
      "LowHigh",
      "HighHigh",
      "PanicLow",
      "NormalLow",
      "PanicHigh",
      "RepeatLow",
      "NormalHigh",
      "RepeatHigh",
      "AgeStart",
      "AgeEnd",
    ];

    const moveKeysToRangeArr = (parentObj) => {
      const newRangeArr = {};
      keysToMoveToRangeArr.forEach((key) => {
        if (parentObj[key] !== undefined) {
          newRangeArr[key] = parentObj[key];
        }
      });
      return [newRangeArr];
    };

    if (data?.ranges && data.ranges.length > 0) {
      const rangeData = data.ranges.map((range) => ({
        RangesAdded: range.RangesAdded || true,
        FilterKey: range.FilterKey,
        // collapse: range.collapse || false,
        collapse: range.FilterKey.length === 0 ? false : true,
        RangeArr: range.RangeArr || moveKeysToRangeArr(range),
      }));

      return [...rangeData, defaultRangeObj];
    }

    return [defaultRangeObj];
  });

  const [refIntervalSelection, setRefIntervalSelection] = useState("ctscore");

  const [resultType, setResultType] = useState("");

  const setRefInterval = (data) => {
    let refType;

    try {
      refType = JSON.parse(data.refType);
    } catch (error) {
      refType = "ctscore";
    }
    setRefIntervalSelection(refType);
  };

  useEffect(() => {
    if (data) {
      setNewItem({ ...newItem, ...data });
      setRefInterval(data);
      setResultType(data?.resultType || "");
    }
  }, []);

  const hasDuplicateFilterKeys = (data) => {
    const filterKeySet = new Set();

    for (const item of data) {
      const filterKey = item.FilterKey || [];

      const filteredFilterKey = filterKey.filter((obj) => obj !== null);
      if (filteredFilterKey.length === 0) continue;

      const serializedFilterKey = JSON.stringify(
        filteredFilterKey.map((obj) => ({
          label: obj.label,
          value: obj.value,
          keyType: obj.keyType,
        }))
      );

      if (filterKeySet.has(serializedFilterKey)) {
        return true;
      }

      filterKeySet.add(serializedFilterKey);
    }

    return false;
  };

  const checkRanges = (data) => {
    const keysOrder = [
      "LowLow",
      "LowHigh",
      "NormalLow",
      "NormalHigh",
      "HighLow",
      "HighHigh",
      "PanicLow",
      "PanicHigh",
      "RepeatLow",
      "RepeatHigh",
    ];

    for (const obj of data) {
      if (!obj.RangesAdded) continue;

      const hasAgeFilter = obj.FilterKey.some((filter) => filter && filter.keyType === "age");

      if (hasAgeFilter) {
        const ageRanges = obj.RangeArr.map((range) => {
          const ageStart = parseFloat(range.AgeStart);
          const ageEnd = parseFloat(range.AgeEnd);
          return { ageStart, ageEnd };
        })
          .filter(({ ageStart, ageEnd }) => !isNaN(ageStart) && !isNaN(ageEnd))
          .sort((a, b) => a.ageStart - b.ageStart);

        if (ageRanges.length === 0) {
          return { valid: false, reason: "Enter Age." };
        }

        for (let i = 0; i < ageRanges.length; i++) {
          const { ageStart, ageEnd } = ageRanges[i];
          if (ageEnd <= ageStart) {
            return { valid: false, reason: "Age group is invalid: AgeEnd should be greater than AgeStart." };
          }

          if (i > 0 && ageStart <= ageRanges[i - 1].ageEnd) {
            return { valid: false, reason: "Duplicate Age: Overlapping age ranges found." };
          }
        }
      }

      for (const range of obj.RangeArr) {
        let previousValue = null;
        let isAllKeysEmpty = true;

        for (const key of keysOrder) {
          const value = range[key];
          if (!value) continue;

          const currentValue = parseFloat(value);
          if (isNaN(currentValue)) {
            return { valid: false, reason: "Invalid Range Value: Non-numeric value found." };
          }

          if (previousValue !== null && currentValue <= previousValue) {
            return { valid: false, reason: "Invalid Range Value: Values are not in increasing order." };
          }

          previousValue = currentValue;
          isAllKeysEmpty = false;
        }

        if (isAllKeysEmpty) {
          return { valid: false, reason: "Invalid Range Value: All range values are empty." };
        }
      }
    }

    return { valid: true, reason: "All ranges are valid." };
  };

  const validateInput = () => {
    const { callFrom } = newItem;

    // console.log("Ranges", ranges);

    // return;

    if (!newItem.name) {
      appContext.showErrorMessage("Enter valid pathogen name");
      return;
    }
    if (callFrom === "pathegon" && !newItem.antibioticResistanceType) {
      appContext.showErrorMessage("Select Antibiotic Resistance Gens");
      return;
    }

    if (callFrom === "test" && (!newItem.units || customIsEmpty(newItem.units))) {
      appContext.showErrorMessage("Select Element units");
      return;
    }

    if (callFrom === "test" && (!newItem.sources || customIsEmpty(newItem.sources))) {
      appContext.showErrorMessage("Select Element Source");
      return;
    }

    const duplicateFilter = hasDuplicateFilterKeys(ranges);

    if (duplicateFilter) {
      appContext.showErrorMessage("Ranges variables should be unique");

      return;
    }

    const isDuplicateRange = checkRanges(ranges);

    if (!isDuplicateRange.valid) {
      appContext.showErrorMessage(isDuplicateRange.reason);

      return;
    }

    if (
      newItem?.typeOfTest === TYPE_OF_TEST.QUANTITATIVE &&
      ranges.filter((item) => item.RangesAdded === true || item.RangesAdded === undefined).length === 0
    ) {
      appContext.showErrorMessage("Add Ranges...");

      return;
    }

    const referenceInterval = ranges && ranges.length > 0 ? generateRefInterval({ ...newItem, ranges: ranges[0] }) : "";

    const testObj = {
      ...newItem,
      units: newItem?.units,
      // refInterval: newItem?.typeOfTest === "Qualitative" ? "Not Detected" : referenceInterval,

      // refInterval:
      //   newItem?.typeOfTest === "Qualitative"
      //     ? "Not Detected"
      //     : refIntervalSelection === "ctscore"
      //     ? referenceInterval
      //     : newItem.refInterval.value,

      refType: newItem?.typeOfTest === "Qualitative" ? "Not Detected" : refIntervalSelection,
      ranges:
        newItem?.typeOfTest === TYPE_OF_TEST.QUANTITATIVE
          ? ranges.filter((item) => item.RangesAdded === true || item.RangesAdded === undefined)
          : null,
      resultType,
      possibleValues: newItem.typeOfTest,
      isActive: true,
    };

    handleSaveTestDetail(testObj);
  };

  const handleInputChange = (field, value) => {
    setNewItem((prev) => ({ ...prev, [field]: value }));
  };

  const handleChangeRanges = (e, rowIndex, index) => {
    const { name, value } = e.target;
    let copyRanges = customCloneDeep(ranges);

    copyRanges[index]["RangeArr"][rowIndex][name] = value;
    setRanges(copyRanges);
  };

  const handleAddRange = (index) => {
    let copyRanges = customCloneDeep(ranges);

    copyRanges[index].RangesAdded = true;
    copyRanges[index].FilterKey = copyRanges[index]?.FilterKey?.filter(Boolean) || [];

    const emptyRangeObj = {
      RangesAdded: false,
      FilterKey: [null],
      collapse: false,
      RangeArr: [emptyRowObj],
    };
    copyRanges.push(emptyRangeObj);

    setRanges(copyRanges);
    // setRanges((prevRanges) => [
    //   ...prevRanges,
    //   {
    //     id: prevRanges.length + 1,

    //     Bacteria: "",

    //     AgeStart: "",
    //     AgeEnd: "",

    //     NormalLow: "",
    //     NormalHigh: "",

    //     HighLow: "",
    //     HighHigh: "",

    //     PanicLow: "",
    //     PanicHigh: "",

    //     RepeatLow: "",
    //     RepeatHigh: "",

    //     LowLow: "",
    //     LowHigh: "",
    //   },
    // ]);
  };

  const handelFilterData = (data, index) => {
    let copyRanges = customCloneDeep(ranges);

    copyRanges[index]["FilterKey"] = data;

    if (index > 0) {
      for (let i = 0; i < copyRanges.length; i++) {
        if (i === index) continue;
        copyRanges[i]["collapse"] = copyRanges[i].FilterKey.length > 0 ? true : false;
      }
    }

    setRanges(copyRanges);
  };

  const handelRemoveNode = (filterIndex, index) => {
    let copyRanges = customCloneDeep(ranges);
    if (filterIndex === 0) {
      copyRanges.splice(index, 1);
    } else if (copyRanges[index]["FilterKey"]?.length > 1) {
      copyRanges[index]["FilterKey"].splice(filterIndex, 1);
    }
    setRanges(copyRanges);
  };

  const handleAddNode = (index) => {
    let copyRanges = customCloneDeep(ranges);

    if (!Array.isArray(copyRanges[index]["FilterKey"])) {
      copyRanges[index]["FilterKey"] = [];
    }

    const filterKeyLength = copyRanges[index]["FilterKey"].length;
    if (filterKeyLength === 0 || copyRanges[index]["FilterKey"][filterKeyLength - 1] !== null) {
      copyRanges[index]["FilterKey"].push(null);
      setRanges(copyRanges);
    } else {
      appContext.showErrorMessage("Fill value in last field.");
    }
  };

  const handelRemoveGrid = (index) => {
    let copyRanges = customCloneDeep(ranges);

    copyRanges.splice(index, 1);

    setRanges(copyRanges);
  };
  const handelCollaspeNode = (index) => {
    let copyRanges = customCloneDeep(ranges);
    copyRanges[index].collapse = !copyRanges[index].collapse;
    // copyRanges.splice(index, 1);

    setRanges(copyRanges);
  };

  const handleAddNewRow = (index) => {
    let copyRanges = customCloneDeep(ranges);
    copyRanges[index]["RangeArr"].push(emptyRowObj);
    setRanges(copyRanges);
  };
  const handelRemoveRow = (rowIndex, index) => {
    let copyRanges = customCloneDeep(ranges);

    if (copyRanges[index]["RangeArr"].length > 1) {
      copyRanges[index]["RangeArr"].splice(rowIndex, 1);
    } else {
      copyRanges.splice(index, 1);
    }
    setRanges(copyRanges);
  };

  const handleChangeGender = (e, index) => {
    let copyRanges = customCloneDeep(ranges);
    copyRanges[index].Gender = e;
    setRanges(copyRanges);
  };
  const handleChangeBacteria = (e, index) => {
    let copyRanges = customCloneDeep(ranges);
    copyRanges[index]["Bacteria"] = e;
    setRanges(copyRanges);
  };
  const handleAddNewNode = () => {
    let copyRanges = customCloneDeep(ranges);

    copyRanges.push(emptyNode);
    setRanges(copyRanges);
  };

  return (
    <Modal
      show
      animation={true}
      className="second-modal modal-padding-0"
      onHide={() => handleClose()}
      centered
      size={data?.callFrom === "pathegon" ? "xl" : "2xl"}
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title className="my-0 h4" id="contained-modal-title-vcenter">
          Test Element Creation
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ paddingTop: 0 }}>
        {/* First row */}

        {loading && <Loader />}

        <div style={{ display: "flex", gap: "16px" }}>
          <div style={{ flex: 1 }}>
            <TestInputField
              label="Name"
              value={newItem.name}
              onChange={(e) => handleInputChange("name", e.target.value)}
              required={true}
            />
          </div>

          {newItem.callFrom === "test" && (
            <div style={{ flex: 1 }}>
              <TestSelector
                value={newItem?.units || null}
                onChange={(e) => setNewItem({ ...newItem, units: e })}
                settingType={"unit"}
                placeholder="Enter Unit Name"
                required={true}
                updateMainTest={(value) => setNewItem({ ...newItem, units: { label: value, value: value } })}
              />
            </div>
          )}

          {newItem.callFrom === "test" && (
            <div style={{ flex: 1 }}>
              <TestSelector
                value={newItem.sources}
                tabIndex={3}
                onChange={(e) => setNewItem({ ...newItem, sources: e })}
                settingType={TEST_SETTING_TYPES.SOURCE}
                label={"Source"}
                required={true}
                isMulti
                title={"Create Source"}
                placeholder="Enter Source Name"
                updateMainTest={(value) => setNewItem({ ...newItem, sources: { label: value, value: value } })}
              />
            </div>
          )}
          {newItem.callFrom === "pathegon" && (
            <div style={{ flex: 1 }}>
              <>
                <p className="modalLineHeaders">Antibiotic-Resistance Genes</p>
                <Select
                  className="w-100"
                  options={ANTIBIOTIC_RESISTANCE_GENES_TYPE}
                  blurInputOnSelect={true}
                  defaultValue={null}
                  menuPlacement="auto"
                  value={
                    newItem.antibioticResistanceType
                      ? { label: newItem.antibioticResistanceType, value: newItem.antibioticResistanceType }
                      : null
                  }
                  placeholder="Select"
                  onChange={(e) =>
                    setNewItem({
                      ...newItem,
                      antibioticResistanceType: e.value,
                    })
                  }
                />
              </>
            </div>
          )}
        </div>

        {/* {newItem.callFrom === "test" && (
          <TestDetailsRangeView
            data={ranges}
            onChange={handleChangeRanges}
            onChangeGender={handleChangeGender}
            onChangeBacteria={handleChangeBacteria}
            handelRemoveView={(data) => handelRemoveGrid(data)}
            handleAddRange={() => handleAddRange()}
          />
        )} */}

        {newItem.callFrom === "test" && newItem.typeOfTest === TYPE_OF_TEST.QUANTITATIVE && (
          <>
            <div style={{ display: "flex", justifyContent: "space-between", marginTop: "20px" }}>
              <h4 style={{ margin: 0 }}>Range Setup</h4>

              <Icon
                iconType={"createIcon"}
                containerClass={"ms-2 cursor-pointer"}
                innerClass={"test-selector-add-icon"}
                handleClick={handleAddNewNode}
              />
            </div>

            <div
              style={{
                border: "1px solid gray",
                marginTop: "10px",
                borderRadius: "10px",
                minHeight: "20vh",
                maxHeight: "60vh",
                overflow: "unset",
              }}
            >
              {ranges &&
                ranges.map((item, index) => {
                  return (
                    <TestDetailsRangeViewNew
                      data={item}
                      onChange={(e, rowIndex) => handleChangeRanges(e, rowIndex, index)}
                      handelRemoveView={() => handelRemoveGrid(index)}
                      handleAddRange={() => handleAddRange(index)}
                      setFilter={(filterData) => handelFilterData(filterData, index)}
                      handelRemoveNode={(filterIndex) => handelRemoveNode(filterIndex, index)}
                      handleAddNode={() => handleAddNode(index)}
                      handelCollaspeNode={() => handelCollaspeNode(index)}
                      handleAddNewRow={() => handleAddNewRow(index)}
                      handelRemoveRow={(rowIndex) => handelRemoveRow(rowIndex, index)}
                    />
                  );
                })}
            </div>
          </>
        )}
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" className="modalButtons" onClick={() => handleClose()}>
          Close
        </Button>

        <Button variant="primary" className="modalButtons" onClick={validateInput}>
          {newItem.isNew ? "Create" : "Update"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default memo(TestDetailsModal);
