import React, { useState, memo } from "react";
import TestElementViewModal from "./TestElementViewModal";

const TestElemensInput = ({
  resistanceRows,
  organismsRows,
  associatedRows,
  handleTestRemove,
  onChange,
  handleCellClick,
  required=false
}) => {
  const [selectedOption, setSelectedOption] = useState("");

  const [showTestElementModal, setShowTestElementModal] = useState(false);

  const handleSelectedOption = (option) => {
    setSelectedOption(option);
    setShowTestElementModal(true);
  };

  return (
    <>
      {showTestElementModal && (
        <TestElementViewModal
          handleClose={() => setShowTestElementModal(false)}
          resistanceRows={resistanceRows}
          organismsRows={organismsRows}
          associatedRows={associatedRows}
          handleTestRemove={handleTestRemove}
          onChange={onChange}
          handleCellClick={handleCellClick}
          filterOption={selectedOption}
        />
      )}

      <div>
        <p className="test-type-input-label">
          Test Elements
          {required && <span style={{ color: "red" }}>*</span>}
        </p>
        <div className="test-element-container">
          <TestElementOptions
            label={`Bacteria/Organisms (${organismsRows.length})`}
            option={`Bacteria/Organisms`}
            onClick={handleSelectedOption}
            selected={selectedOption === "Bacteria/Organisms"}
          />
          <TestElementOptions
            option={`Resistance Genes`}
            label={`Resistance Genes (${resistanceRows.length})`}
            onClick={handleSelectedOption}
            selected={selectedOption === "Resistance Genes"}
          />
          <TestElementOptions
            option={`Associated Test`}
            label={`Associated Test (${associatedRows.length})`}
            onClick={handleSelectedOption}
            selected={selectedOption === "Associated Test"}
          />
          {/* <p className="test-element-view-label" onClick={() => setShowTestElementModal(true)}>
            View
          </p> */}
        </div>
      </div>
    </>
  );
};

export default memo(TestElemensInput);

const TestElementOptions = memo(({ option, label, selected = false, onClick }) => {
  return (
    <div
      className={`test-element-option-container ${selected ? "test-element-option-container-selected" : ""}`}
      onClick={() => onClick(option)}
    >
      <p>{label}</p>
    </div>
  );
});
