import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import Select from "react-select";

const SourceSiteModal = ({ handleClose, clients, handleAddSourceSite }) => {

  const [inputValue, setInputValue] = useState("");
  const [selectedOption, setSelectedOption] = useState(null);

  const handleChangeSourceSite = (option) => {
    setSelectedOption(option);
    setInputValue("");
  };

  const handleInputChange = (newValue, { action }) => {
    if (action === "input-change") {
      setSelectedOption(null);
      setInputValue(newValue);
    }
  };

  const saveSourceSite = () =>{
    const value =  selectedOption ? selectedOption.label : inputValue
    handleAddSourceSite(value)
  }


  return (
    <Modal
      show
      animation={true}
      onHide={() => handleClose()}
      backdrop="static"
      centered
      size={"md"}
      keyboard={true}
      className="second-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title className="my-0" id="contained-modal-title-vcenter">
          Source Site
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ paddingTop: 0 }}>
        <div className="form-group-wrapper">
          <label className="modalLineHeaders">Source Site</label>
          <Select
            className="w-100 siteSelector"
            options={clients}
            blurInputOnSelect={true}
            menuPlacement="auto"
            placeholder="Select Source Site"
            value={selectedOption} // Control the selected option
            inputValue={inputValue} // Control the typed input value
            onChange={handleChangeSourceSite} // Handle option selection
            onInputChange={handleInputChange} // Handle typing input
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" className="headerButton" onClick={() => handleClose()}>
          Close
        </Button>
        <Button variant="primary" className="modalButtons" onClick={saveSourceSite}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SourceSiteModal;
