import React from "react";

import { toTitleCase } from "utils";
import Select from "react-select";
import { panelSelector } from "store/features/testAssay/testAssaySelector";
import { useSelector } from "react-redux";

import { ELEMENT_TYPE } from "constant";

const TestDetailsSelector = ({
  label = "Select",
  value,
  onChange,
  placeholder = "Select an option...",
  className = "",
  filterOption,
}) => {
  const testPanels = useSelector(panelSelector);

  const panelsOptions = testPanels
    ?.filter((item) => {
      if (ELEMENT_TYPE[filterOption]) {
        return item.antibioticResistanceType === ELEMENT_TYPE[filterOption];
      }
      return true;
    })
    .map((item) => {
      return {
        ...item,
        label: item.name,
        value: item.id,
      };
    });

  return (
    <div className="row mt-2">
      <div>
        <p className="testDetailsSelector">{filterOption}</p>
        <div className="d-flex align-items-center">
          <Select
            className={`w-100 ${className}`}
            options={panelsOptions}
            blurInputOnSelect={true}
            menuPlacement={"auto"}
            value={value}
            placeholder={placeholder}
            onChange={onChange}
          />
        </div>
      </div>
    </div>
  );
};

export default TestDetailsSelector;
