import { ANTIBIOTIC_RESISTANCE_GENES_TYPE } from "constant";

import React, { useContext, useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import Select from "react-select";
import { getPanelElementType, createChangeObjForLogs } from "utils";
import API from "api";
import TestInputField from "./TestInputField";

import { AppContext } from "context/app-context";
import Loader from "components/Loader/Loader";

const TestElementModal = ({ data, handleSaveTestDetail, handleClose, loading }) => {
  const appContext = useContext(AppContext);

  const [newItem, setNewItem] = useState({
    isNew: true,
    name: "",
    units: null,
    refInterval: "",
    isActive: "",
  });

  const [resultType, setResultType] = useState("");

  useEffect(() => {
    if (data) {
      setNewItem({ ...newItem, ...data });
      setResultType(data?.resultType || "");
    }
  }, [data]);

  const saveLogs = () => {
    const fieldsName = {
      name: "Element Name",
      antibioticResistanceType: "Element Type",
    };

    const chngObj = createChangeObjForLogs({
      oldRecord: data,
      newRecord: newItem,
      fieldsName,
    });

    if (chngObj.length > 0) {
      const logObj = {
        updatedBy: appContext?.user?.sub,
        updatedByName: appContext?.user?.name,
        userID: appContext?.user?.sub,
        userName: appContext?.user?.name,
        testID: newItem.id,
        slug: "Update",
        changeObject: chngObj,
      };
      API.addLogs(logObj);
    }
  };

  const validateInput = () => {
    const { callFrom } = newItem;

    if (!newItem.name) {
      appContext.showErrorMessage("Enter valid element name");
      return;
    }
    if (callFrom === "pathegon" && !newItem.antibioticResistanceType) {
      appContext.showErrorMessage("Select Test Element Type");
      return;
    }

    const testObj = {
      ...newItem,
      resultType,
      isActive: true,
    };

    saveLogs();

    handleSaveTestDetail(testObj);
  };

  const handleInputChange = (field, value) => {
    setNewItem((prev) => ({ ...prev, [field]: value }));
  };

  return (
    <Modal
      show
      animation={true}
      className="second-modal modal-padding-0"
      onHide={() => handleClose()}
      centered
      size={data?.callFrom === "pathegon" ? "xl" : "2xl"}
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title className="my-0 h4" id="contained-modal-title-vcenter">
          Panel Element Management
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ paddingTop: 0 }}>
        {/* First row */}

        {loading && <Loader />}

        <div style={{ display: "flex", gap: "16px" }}>
          <div style={{ flex: 1 }}>
            <TestInputField
              label="Name"
              value={newItem.name}
              onChange={(e) => handleInputChange("name", e.target.value)}
              required={true}
            />
          </div>

          {newItem.callFrom === "pathegon" && (
            <div style={{ flex: 1 }}>
              <>
                <p className="modalLineHeaders">Element Type</p>
                <Select
                  className="w-100"
                  options={ANTIBIOTIC_RESISTANCE_GENES_TYPE}
                  blurInputOnSelect={true}
                  defaultValue={null}
                  menuPlacement="auto"
                  value={getPanelElementType(newItem.antibioticResistanceType)}
                  placeholder="Select"
                  onChange={(e) =>
                    setNewItem({
                      ...newItem,
                      antibioticResistanceType: e.value,
                    })
                  }
                />
              </>
            </div>
          )}
        </div>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" className="modalButtons" onClick={() => handleClose()}>
          Close
        </Button>

        <Button variant="primary" className="modalButtons" onClick={validateInput}>
          {newItem.isNew ? "Create" : "Update"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default TestElementModal;
