import React, { memo } from "react";
import { Button, Modal } from "react-bootstrap";
import MainTable from "components/Table/MainTable";
import { CREATE_TEST_PERSONALIZE } from "constants/personalization";
import Icon from "components/Icon";
import { renderSourcesCell } from "utils";

import { TABLE_QUICK_TOOLS } from "constant";
import TestDetailsSelector from "../TestDetailsSelector";

const TestElementViewModal = ({
  handleClose,
  handleTestRemove,
  organismsRows,
  resistanceRows,
  associatedRows,
  onChange,
  handleCellClick,
  filterOption,
}) => {

  const customRenderTD = (item, row, index) => {
    if (item.itemKey === "action") {
      return (
        <td key={`bin_${item.id}`} style={{ textAlign: "right" }}>
          <Icon
            iconType={"binIcon"}
            containerClass={"ms-2 cursor-pointer"}
            innerClass={""}
            imageClass={"testTabelDeleteIcon"}
            handleClick={() => handleTestRemove(row)}
          />
        </td>
      );
    }
    if (item.itemKey === "units") {
      return <td key={`unit_${item.id}`}>{row?.units?.label ? row.units.label : ""}</td>;
    }
    if (item.itemKey === "sources") {
      return renderSourcesCell(item, row);
    }
  };

  const quickTools = [TABLE_QUICK_TOOLS.edit];

  return (
    <Modal
      show
      animation={true}
      onHide={() => handleClose()}
      centered
      size={"xl"}
      backdrop="static"
      className="second-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title className="my-0 h4" id="contained-modal-title-vcenter">
          Test Elements
        </Modal.Title>
      </Modal.Header>

      <Modal.Body
        className="testAndAssayManagementModal"
        style={{ padding: "10px", paddingTop: "0px", paddingLeft: "30px", paddingRight: "30px", paddingBottom: "30px" }}
      >
        <TestDetailsSelector
          label="Select Pathogen & Resistance Markers"
          onChange={onChange}
          value={null}
          filterOption={filterOption}
          placeholder="Select Element"
        />

        {organismsRows.length > 0 && filterOption === "Bacteria/Organisms" && (
          <>
            <p className="mt-2 h4">Bacteria/Organisms</p>
            <div style={PATHOGEN_TABLE_STYLE}>
              <MainTable
                columns={CREATE_TEST_PERSONALIZE}
                rows={organismsRows || []}
                customColumnCellRenderer={customRenderTD}
                tools={quickTools}
                widthToSkip={155}
                handleCellClick={handleCellClick}
              />
            </div>
          </>
        )}

        {resistanceRows.length > 0 && filterOption === "Resistance Genes" && (
          <>
            <p className="mt-2 h4">Resistance Genes</p>
            <div style={PATHOGEN_TABLE_STYLE}>
              <MainTable
                columns={CREATE_TEST_PERSONALIZE}
                rows={resistanceRows || []}
                customColumnCellRenderer={customRenderTD}
                tools={quickTools}
                widthToSkip={155}
                handleCellClick={handleCellClick}
              />
            </div>
          </>
        )}
        {associatedRows.length > 0 && filterOption === "Associated Test" && (
          <>
            <p className="mt-2 h4">Associated Test</p>
            <div style={PATHOGEN_TABLE_STYLE}>
              <MainTable
                columns={CREATE_TEST_PERSONALIZE}
                rows={associatedRows || []}
                customColumnCellRenderer={customRenderTD}
                tools={quickTools}
                widthToSkip={155}
                handleCellClick={handleCellClick}
              />
            </div>
          </>
        )}
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" className="modalButtons" onClick={() => handleClose()}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default memo(TestElementViewModal);

const PATHOGEN_TABLE_STYLE = { background: "#f2f0f0", paddingLeft: "4px", paddingRight: "4px" };
